/*fonts*/
@font-face {
    font-family: 'Graphik LCG';
    src: url('fonts/graphiklcg-bold-webfont.eot');
    src: url('fonts/graphiklcg-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/graphiklcg-bold-webfont.woff2') format('woff2'),
         url('fonts/graphiklcg-bold-webfont.woff') format('woff'),
         url('fonts/graphiklcg-bold-webfont.ttf') format('truetype'),
         url('fonts/graphiklcg-bold-webfont.svg#graphik_lcgbold') format('svg');
    font-weight: bold;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: 'Graphik LCG';
    src: url('fonts/graphiklcg-medium-webfont.eot');
    src: url('fonts/graphiklcg-medium-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/graphiklcg-medium-webfont.woff2') format('woff2'),
         url('fonts/graphiklcg-medium-webfont.woff') format('woff'),
         url('fonts/graphiklcg-medium-webfont.ttf') format('truetype'),
         url('fonts/graphiklcg-medium-webfont.svg#graphik_lcgmedium') format('svg');
    font-weight: 500;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: 'Graphik LCG';
    src: url('fonts/graphiklcg-regular-webfont.eot');
    src: url('fonts/graphiklcg-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/graphiklcg-regular-webfont.woff2') format('woff2'),
         url('fonts/graphiklcg-regular-webfont.woff') format('woff'),
         url('fonts/graphiklcg-regular-webfont.ttf') format('truetype'),
         url('fonts/graphiklcg-regular-webfont.svg#graphik_lcgregular') format('svg');
    font-weight: normal;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: 'Graphik LCG';
    src: url('fonts/graphiklcg-regularitalic-webfont.eot');
    src: url('fonts/graphiklcg-regularitalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/graphiklcg-regularitalic-webfont.woff2') format('woff2'),
         url('fonts/graphiklcg-regularitalic-webfont.woff') format('woff'),
         url('fonts/graphiklcg-regularitalic-webfont.ttf') format('truetype'),
         url('fonts/graphiklcg-regularitalic-webfont.svg#graphik_lcgregular_italic') format('svg');
    font-weight: normal;
    font-display: swap;
    font-style: italic;
}

@font-face {
    font-family: 'Graphik LCG';
    src: url('fonts/graphiklcg-semibold-webfont.eot');
    src: url('fonts/graphiklcg-semibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/graphiklcg-semibold-webfont.woff2') format('woff2'),
         url('fonts/graphiklcg-semibold-webfont.woff') format('woff'),
         url('fonts/graphiklcg-semibold-webfont.ttf') format('truetype'),
         url('fonts/graphiklcg-semibold-webfont.svg#graphik_lcgsemibold') format('svg');
    font-weight: 600;
    font-display: swap;
    font-style: normal;
}

/*reset light*/
html, body {
    margin: 0;
    padding: 0;
    color: #404040;
}
label, input, button, a {
    -webkit-tap-highlight-color: transparent;
}
body {
    font-family: 'Graphik LCG', 'sans-serif', 'arial', 'helvetica';
    -webkit-font-smoothing: antialiased;
    --c1: #95813A;
}
svg path, svg rect, svg circle {
    -webkit-transition: 0.3s;
       -moz-transition: 0.3s;
        -ms-transition: 0.3s;
         -o-transition: 0.3s;
            transition: 0.3s;
}
input, textarea, select, button {
    font-family: 'Graphik LCG', 'sans-serif', 'arial', 'helvetica';
}
input:not([type=checkbox]):not([type=radio]),
textarea,
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
a:hover {
    text-decoration: none;
}
img, a, input, textarea, select, button {
    -webkit-border-radius: 0;
            border-radius: 0;
    outline-style: none;
}
button::-moz-focus-inner {
  border: 0;
}
body::-webkit-scrollbar, *::-webkit-scrollbar {
  width: 16px;
}
body::-webkit-scrollbar-track, *::-webkit-scrollbar-track {
  background: #3C484F;
}
body::-webkit-scrollbar-thumb, *::-webkit-scrollbar-thumb {
  background-color: #95813A;
  border-radius: 0;
}
body {
  scrollbar-width: thin;
  scrollbar-color: #95813A #3C484F;
}

input {
    box-shadow: none;
    -webkit-box-shadow: none;
}
.container_main {
    min-width: 320px;
    overflow: hidden;
}
.clr {
    clear: both;
}
.align_center {
    text-align: center;
    display: flex;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}
.wmain {
    margin: 0 auto;
    width: 1672px;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
}

.overflow {
    overflow: hidden;
}

.plr {
    padding-left: 165px;
    padding-right: 83px;
}


.header {
    background-repeat: no-repeat !important;
    -webkit-background-size: cover !important;
         -o-background-size: cover !important;
            background-size: cover !important;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
}

    .header .wmain {
        height: 100vh;
        display: flex;
        -webkit-flex-direction: column;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
        padding-top: 83px;
        padding-bottom: 242px;
        min-height: 800px;
        position: relative;
        -webkit-box-sizing: border-box;
           -moz-box-sizing: border-box;
                box-sizing: border-box;
    }

    .header_center h1 {
        display: block;
        margin: 0;
        padding: 0;
        text-align: center;
        font-weight: bold;
        text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
        color: #FFF;
        font-size: 56px;
        line-height: 110%;
    }


.header_main {
    position: relative;
}

    .header_main .wmain {
        position: relative;
    }


.head_sub {
    position: relative;
    margin-top: 29px;
    padding-top: 33px;
    text-align: center;
    font-size: 24px;
    line-height: 120%;
    color: #FFF;
}

    .head_sub:before {
        content: "";
        display: block;
        position: absolute;
        width: 84px;
        height: 4px;
        background: #95813A;
        top: 0;
        left: 50%;
        -webkit-transform: translateX(-50%);
           -moz-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
             -o-transform: translateX(-50%);
                transform: translateX(-50%);
    }

.header_center .align_center {
    margin-top: 29px;
}

.btn_main {
    cursor: pointer;
    white-space: nowrap;
    text-align: center;
    display: inline-block;
    vertical-align: top;
    outline-style: none;
    background: #000000;
    font-size: 20px;
    line-height: 20px;
    color: #FFF;
    font-weight: 600;
    padding-left: 73px;
    padding-right: 73px;
    padding-top: 12px;
    padding-bottom: 18px;
    text-decoration: none;
    border: 2px solid #FFFFFF;
    border-radius: 5px;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    -webkit-transition: 0.6s;
       -moz-transition: 0.6s;
        -ms-transition: 0.6s;
         -o-transition: 0.6s;
            transition: 0.6s;
}

    .btn_main:hover {
        background: var(--c1);
        -webkit-transition: 0.3s;
           -moz-transition: 0.3s;
            -ms-transition: 0.3s;
             -o-transition: 0.3s;
                transition: 0.3s;
    }

.btn_c1 {
    background: transparent;
    color: #FFF;
    font-size: 12px;
    border-color: var(--c1);
    padding-top: 4px;
    padding-bottom: 4px;
    font-weight: normal;
    border-width: 2px;
}

    .btn_c1:hover {
        background: var(--c1);
    }

.btn_c3 {
    background: transparent;
    color: #FFF;
    font-size: 20px;
    line-height: 100%;
    border-color: #fff;
    padding-top: 13px;
    padding-bottom: 17px;
    font-weight: 600;
    border-width: 2px;
}

    .btn_c3:hover {
        background: #fff;
        color: var(--c2);
    }

.header__list {
    position: absolute;
    display: flex;
    z-index: 60;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin: 0;
    padding: 0;
    list-style: none;
    width: 100%;
    left: 0;
    bottom: 62px;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
}

    .header__list > li {
        width: calc(25% - 16px);
        position: relative;
        cursor: pointer;
        top: 0;
        -webkit-transition: 0.3s;
           -moz-transition: 0.3s;
            -ms-transition: 0.3s;
             -o-transition: 0.3s;
                transition: 0.3s;
    }

        .header__list > li:hover {
            top: -10px;
        }

.hl__item {
    position: relative;
    width: 100%;
    height: 140px;
    display: flex;
    padding-left: 20px;
    padding-right: 20px;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    padding-top: 35px;
    background: rgba(255, 255, 255, 0.03);
    border: 1px solid rgba(255, 255, 255, 0.5);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(5px);
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
}

.hl__ico {
    position: absolute;
    z-index: 20;
    width: 80px;
    height: 80px;
    top: -40px;
    left: 50%;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    margin-left: -32px;
    background: rgba(255, 255, 255, 0.03);
    border: 1px solid rgba(255, 255, 255, 0.5);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(5px);
    -webkit-border-radius: 50%;
            border-radius: 50%;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
}

    .hl__ico img {
        display: block;
    }

.hl__name {
    text-align: center;
    font-size: 24px;
    line-height: 26px;
    color: #FFF;
    font-weight: 600;
}

.hl_top {
    position: fixed;
    z-index: 100;
    left: 105px;
    right: 0;
    padding-left: 60px;
    top: 0;
    padding-top: 0;
    padding-bottom: 0;
    background: transparent;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
    -webkit-transition: 0.5s;
       -moz-transition: 0.5s;
        -ms-transition: 0.5s;
         -o-transition: 0.5s;
            transition: 0.5s;
}

    .hl_top_act {
        padding-top: 5px;
        padding-bottom: 5px;
        background: rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(5px);
    }

    .hl_top .wmain {
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: space-between;
                justify-content: space-between;
    }

.hl_city {
    display: flex;
    font-size: 16px;
    line-height: 19px;
    color: #FFF;
    -webkit-align-content: center;
            align-content: center;
}

    .hl_city img {
        display: block;
        margin-right: 11px;
        position: relative;
        top: 1px;
    }

.logo {
    margin-left: 20px;
    margin-right: 20px;
}

.logo img {
    display: block;
    max-width: 420px;
    -webkit-transition: 0.3s;
       -moz-transition: 0.3s;
        -ms-transition: 0.3s;
         -o-transition: 0.3s;
            transition: 0.3s;
}

    .hl_top_act .logo img {
        max-width: 280px;
    }

.hl_phone {
    display: block;
    text-decoration: none;
    font-size: 32px;
    color: #FFF;
    line-height: 110%;
    font-weight: 600;
    -webkit-transition: 0.5s;
       -moz-transition: 0.5s;
        -ms-transition: 0.5s;
         -o-transition: 0.5s;
            transition: 0.5s;
}

    .hl_top_act .hl_phone {
        font-size: 18px;
    }

.hl_right .btn_main {
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 7px;
    width: 100%;
}

.fixed_l {
    position: fixed;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    height: 100%;
    left: 0;
    width: 105px;
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(5px);
    top: 0;
    z-index: 300;
    -webkit-transition: 0.5s;
       -moz-transition: 0.5s;
        -ms-transition: 0.5s;
         -o-transition: 0.5s;
            transition: 0.5s;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.fixed_l_logo {
    position: absolute;
    width: 105px;
    left: 0;
    top: 12px;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

    .fixed_l_logo img {
        display: block;
        max-width: 100%;
    }


.fixed_menu_ico {
    cursor: pointer;
    position: absolute;
    width: 30px;
    height: 24px;
    top: 50%;
    margin-top: -12px;
    left: 37px;
    z-index: 50;
}

    .fixed_menu_ico:before {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        padding: 20px;
        left: -10px;
        top: -10px;
    }

    .fixed_menu_ico span {
        position: relative;
        display: block;
        width: 100%;
        height: 4px;
        top: 0;
        left: 0;
        background: #D9D9D9;
        -webkit-transform-origin: left top;
           -moz-transform-origin: left top;
            -ms-transform-origin: left top;
             -o-transform-origin: left top;
                transform-origin: left top;
        -webkit-transition: 0.4s;
           -moz-transition: 0.4s;
            -ms-transition: 0.4s;
             -o-transition: 0.4s;
                transition: 0.4s;
    }

    .fixed_menu_ico span + span {
        margin-top: 6px;
    }


.fixed_l__act .fixed_menu_ico span:nth-child(2) {
    opacity: 0;
    left: 500%;
    -webkit-transform: scale(0.5,0);
       -moz-transform: scale(0.5,0);
        -ms-transform: scale(0.5,0);
         -o-transform: scale(0.5,0);
            transform: scale(0.5,0);
}

.fixed_l__act .fixed_menu_ico span:nth-child(1) {
    left: 6px;
    -webkit-transform: rotate(45deg);
       -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
         -o-transform: rotate(45deg);
            transform: rotate(45deg);
}

.fixed_l__act .fixed_menu_ico span:nth-child(3) {
    top: 1px;
    left: 3px;
    -webkit-transform: rotate(-45deg);
       -moz-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
         -o-transform: rotate(-45deg);
            transform: rotate(-45deg);
}

.fixed_l__act {
    width: 368px;
}

.fixed_menu {
    padding-left: 59px;
    -webkit-transition: 0.3s;
       -moz-transition: 0.3s;
        -ms-transition: 0.3s;
         -o-transition: 0.3s;
            transition: 0.3s;
    visibility: hidden;
    opacity: 1;
    -webkit-transform: scale(0);
       -moz-transform: scale(0);
        -ms-transform: scale(0);
         -o-transform: scale(0);
            transform: scale(0);
}

        .fixed_l__act .fixed_menu {
            visibility: visible;
            opacity: 1;
            -webkit-transition: 0.6s;
               -moz-transition: 0.6s;
                -ms-transition: 0.6s;
                 -o-transition: 0.6s;
                    transition: 0.6s;
            -webkit-transform: scale(1);
               -moz-transform: scale(1);
                -ms-transform: scale(1);
                 -o-transform: scale(1);
                    transform: scale(1);
            -webkit-transition-delay: 0.1s;
               -moz-transition-delay: 0.1s;
                -ms-transition-delay: 0.1s;
                 -o-transition-delay: 0.1s;
                    transition-delay: 0.1s;
        }

.fixed_menu_logo {
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.fixed_menu_logo img {
    display: block;
    max-width: 100%;
}

.fixed_menu_nav {
    position: relative;
    margin-top: 25px;
    padding-top: 29px;
    top: 14px;
}

    .fixed_menu_nav:before {
        content: "";
        display: block;
        position: absolute;
        width: 84px;
        height: 4px;
        background: #95813A;
        left: 50%;
        margin-left: -42px;
        top: 0;
    }

    .fixed_menu_nav a {
        display: block;
        text-align: center;
        text-decoration: none;
        font-size: 24px;
        line-height: 120%;
        color: #FFF;
        white-space: nowrap;
    }

        .fixed_menu_nav a span {
            display: inline-block;
            border-bottom: 2px solid transparent;
            -webkit-transition: 0.3s;
               -moz-transition: 0.3s;
                -ms-transition: 0.3s;
                 -o-transition: 0.3s;
                    transition: 0.3s;
        }

        .fixed_menu_nav a:hover span {
            border-color: var(--c1);
        }

        .fixed_menu_nav a + a {
            margin-top: 26px;
        }

.city_infra {
    padding-top: 37px;
    padding-bottom: 32px;
    background: #D9D9D9;
}

.comfort .tm {
    color: #FFF;
    max-width: 100%;
}

.tm {
    position: relative;
    max-width: 810px;
    margin: 0 auto;
    text-align: center;
    font-size: 40px;
    line-height: 110%;
    color: #404040;
    padding-bottom: 33px;
    font-weight: 600;
}
    .tm:before {
        content: "";
        display: block;
        position: absolute;
        width: 84px;
        height: 4px;
        bottom: 0;
        background: #95813A;
        left: 50%;
        margin-left: -42px;
    }

.tm_dop {
    max-width: 1143px;
    margin: 0 auto;
    margin-top: 37px;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
}

.city_infra__list {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
    margin-left: -32px;
    margin-bottom: -32px;
    margin-top: 49px;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
}

    .city_infra__list > li {
        width: 33.3333333%;
        padding-left: 32px;
        margin-bottom: 32px;
        -webkit-box-sizing: border-box;
           -moz-box-sizing: border-box;
                box-sizing: border-box;
    }

    .city_infra__list > li + li + li + li {
        width: 50%;
    }

.cil__item {
    position: relative;
    overflow: hidden;
    -webkit-box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
            box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
}

.cil__img img {
    display: block;
    width: 100%;
}

.cil__name {
    position: absolute;
    z-index: 10;
    left: 0;
    bottom: 0;
    width: 100%;
    color: #FFF;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 24px;
    font-weight: 600;
    line-height: 26px;
    padding-top: 22px;
    padding-bottom: 28px;
    opacity: 1;
    background: rgba(0, 0, 0, 0.7);
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
    -webkit-transition: 0.4s;
       -moz-transition: 0.4s;
        -ms-transition: 0.4s;
         -o-transition: 0.4s;
            transition: 0.4s;
}

    .cil__item:hover .cil__name {
        -webkit-transform: translateY(100%) scale(1,0);
           -moz-transform: translateY(100%) scale(1,0);
            -ms-transform: translateY(100%) scale(1,0);
             -o-transform: translateY(100%) scale(1,0);
                transform: translateY(100%) scale(1,0);
        opacity: 0;
    }

.cil__content {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 50;
    display: flex;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    text-align: center;
    padding-left: 15px;
    padding-right: 15px;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
    visibility: hidden;
    opacity: 0;
    top: -80px;
    -webkit-transition: 0.4s;
       -moz-transition: 0.4s;
        -ms-transition: 0.4s;
         -o-transition: 0.4s;
            transition: 0.4s;
}

    .cil__item:hover .cil__content {
        top: 0;
        visibility: visible;
        opacity: 1;
    }

.cil__name_inner {
    font-size: 24px;
    line-height: 26px;
    color: #FFF;
    font-weight: 600;
}

.cil__text {
    position: relative;
    text-align: center;
    font-size: 16px;
    line-height: 19px;
    padding-top: 24px;
    max-width: 390px;
    margin: 0 auto;
    margin-top: 20px;
    color: #FFF;
}

    .cil__text:before {
        content: "";
        display: block;
        position: absolute;
        width: 84px;
        height: 4px;
        background: #95813A;
        top: 0;
        left: 50%;
        margin-left: -42px;
    }

.form_style1 {
    display: flex;
    margin-top: 66px;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.in_style {
    display: block;
    min-width: 252px;
    height: 54px;
    padding-left: 19px;
    padding-right: 19px;
    background: #FFFFFF;
    border: none;
    font-size: 16px;
    color: #000;
    box-shadow: inset 2px 2px 3px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    outline-style: none;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
}

.form_style1 .in_style {
    width: 54px;
    margin-right: 32px;
}

.form_style1 .in_style + .in_style {
    width: 252px;
}

.form_style1 .btn_main {
    width: 394px;
    padding-left: 15px;
    padding-right: 15px;
}

.btn_c2 {
    color: #FFF;
    font-size: 20px;
    font-weight: 600;
    border-color: #FFF;
    background: #000;
}

.children {
    background: #464451;
    padding-top: 48px;
    padding-bottom: 57px;
}

.children .tm {
    color: #FFF;
}

.children_list {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
    margin-top: 32px;
    margin-left: -32px;
    margin-bottom: -32px;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
}

    .children_list > li {
        width: 50%;
        padding-left: 32px;
        margin-bottom: 32px;
        -webkit-box-sizing: border-box;
           -moz-box-sizing: border-box;
                box-sizing: border-box;
    }

.children_item {
    position: relative;
}

.children_img {
    position: relative;
    /* overflow: hidden; */
    -webkit-box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
            box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
}

    .children_img img {
        display: block;
        width: 100%;
        transition: 1s;
    }

    .children_img img + img {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        opacity: 0;
        z-index: 10;
    }

    .children_img:hover img {
        opacity: 1;
    }

    .children_img:hover img + img {
        opacity: 1;
        cursor: auto;
    }

.children_play {
    overflow: hidden;
}

.children_play .children_img:before {
    content: "";
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 10;
    display: none !important;
    background: url("../img/play_ico.svg") no-repeat center center;
    -webkit-transition: 0.2s;
       -moz-transition: 0.2s;
        -ms-transition: 0.2s;
         -o-transition: 0.2s;
            transition: 0.2s;
}

.children_play {
    cursor: pointer;
}

    .children_img:hover:before {
        opacity: 0.8;
        -webkit-transform: scale(1.1);
           -moz-transform: scale(1.1);
            -ms-transform: scale(1.1);
             -o-transform: scale(1.1);
                transform: scale(1.1);
    }

.children_name {
    margin-top: 32px;
    font-size: 24px;
    line-height: 28px;
    color: #FFF;
    text-align: center;
}

.children_map {
    margin-top: 46px;
    height: 681px;
    max-height: 80vh;

    -webkit-background-size: cover;
         -o-background-size: cover;
            background-size: cover;
}

.gallery_th_img img {
    display: block;
    width: 100%;
    cursor: pointer;
}

.gallery_th_img {
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
}

.gallery_big_img img {
    display: block;
    width: 100%;
}

.gallery_big_img {
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
}

.gallery {
    background: #D9D9D9;
    padding-bottom: 47px;
}
.gallery_big {
    padding-top: 57px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 23px;
}
.gallery_th {
    padding-top: 10px;
    padding-bottom: 32px;
    padding-left: 20px;
    padding-right: 20px;
}

.gallery_slider {
    margin-left: -20px;
    margin-right: -20px;
}

.gallery_big .swiper-button-prev, .gallery_big .swiper-button-next {
    display: none;
}

.gallery_th .swiper-button-prev, .gallery_th .swiper-button-next {
    margin-top: -52px;
}

.form_style2 {
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.form_style2 .in_style {
    width: 100%;
    margin-right: 32px;
}

.form_style2 .btn_main {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
}

.city_infra__list2 > li + li + li + li {
    width: 25%;
}

.city_infra__list2 .cil__name {
    font-size: 20px;
    text-transform: uppercase;
    line-height: 24px;
    font-weight: 500;
}

.city_infra__list2 .cil__name_inner {
    font-size: 20px;
    line-height: 24px;
}

.comfort {
    background: #464451;
    padding-top: 56px;
    padding-bottom: 0;
}

.flat {
    padding-top: 57px;
    padding-bottom: 63px;
    background: #464451;
    overflow: hidden;
    width: 100%;
    box-sizing: border-box;
}

    .flat .tm {
        color: #FFF;
    }

.flat_nav {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
    -webkit-align-items: center;
            align-items: center;
    margin-top: 40px;
    margin-left: -32px;
    -webkit-justify-content: center;
            justify-content: center;
}

    .flat_nav > li {
        width: 100%;
        padding-left: 32px;
        -webkit-box-sizing: border-box;
           -moz-box-sizing: border-box;
                box-sizing: border-box;
    }

    .flat_nav .btn_main {
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
    }


body .flat_nav .btn_main.act {
    border-color: #95813A;
    background: #000;
    color: #FFF;
}

.flat_area {
    margin-top: 53px;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.flat_area__title {
    text-align: center;
    font-size: 20px;
    line-height: 100%;
    font-weight: 600;
    color: #FFF;
}

.flat_area__slid {
    max-width: 820px;
    margin: 0 auto;
    margin-top: 68px;
}

.fas__num_main {
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.fas__num {
    width: 27px;
    text-align: center;
    position: relative;
    font-size: 12px;
    color: #FFF;
    line-height: 130%;
    padding-bottom: 15px;
}

    .fas__num:before {
        content: "";
        display: block;
        position: absolute;
        width: 1px;
        height: 8px;
        left: 50%;
        margin-left: -1px;
        background: #FFF;
        bottom: 0;
    }

.fas__line {
    position: relative;
    width: 100%;
    height: 10px;
    border-radius: 4px;
    background: #10151C;
    margin-top: 17px;
    box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.48);
}

.fas__line_inner {
    position: absolute;
    height: 100%;
    background: #95813A;
    border-radius: 4px;
    top: 0;
    left: 0;
}

.fas__line_btn_l, .fas__line_btn_r {
    cursor: pointer;
    position: absolute;
    width: 32px;
    height: 32px;
    top: 100%;
    margin-top: 6px;
    left: -16px;
}

.fas__line_btn_r {
    left: auto;
    right: -16px;
}

.fas__line_btn_l:before, .fas__line_btn_r:before {
    content: "";
    display: block;
    position: absolute;
    width: 46px;
    height: 42px;
    left: -7px;
    top: -2px;
    background: url("../img/slid_str.svg") no-repeat center center;
}

.flat_list {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
    margin-top: 96px;
    margin-left: -32px;
    margin-bottom: -32px;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
}

    .flat_list > li {
        width: 25%;
        padding-left: 32px;
        margin-bottom: 32px;
        -webkit-box-sizing: border-box;
           -moz-box-sizing: border-box;
                box-sizing: border-box;
    }

.flat_item {
    position: relative;
    background: rgba(255, 255, 255, 0.03);
    border: 1px solid rgba(255, 255, 255, 0.5);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(5px);
    padding: 22px 19px 19px 19px;
    cursor: pointer;
    top: 0;
    -webkit-transition: 0.3s;
       -moz-transition: 0.3s;
        -ms-transition: 0.3s;
         -o-transition: 0.3s;
            transition: 0.3s;
}

    .flat_item:hover {
        top: -10px;
    }

.fi__name {
    text-align: center;
    font-size: 30px;
    line-height: 120%;
    color: #FFF;
    font-weight: 600;
}

.fi__area {
    display: flex;
    margin-top: 12px;
    color: #FFF;
    font-size: 16px;
    line-height: 120%;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.fi_img {
    height: 396px;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

    .fi_img img {
        display: block;
        max-width: 100%;
        max-height: 360px;
    }

.flat_item .btn_main {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
}

.flat_list_other {
    margin-top: 40px;
}

.get_flat {
    background: #D9D9D9;
    overflow: hidden;
}

.get_flat .wmain {
    display: flex;
}

.get_flat_l {
    width: 50%;
    min-width: 50%;
    padding-top: 36px;
    padding-bottom: 35px;
    padding-right: 32px;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
}

.get_flat_r {
    width: 50%;
    min-width: 50%;
    position: relative;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
}


.get_flat_img {
    display: flex;
    height: 100%;
    position: relative;
    cursor: pointer;
    width: calc(50% + (100vw / 2 - 50%) - 40px);
    -webkit-align-items: center;
            align-items: center;
}

    .get_flat_img:before {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        background: url("../img/play_ico.svg") no-repeat center center;
        -webkit-transition: 0.3s;
           -moz-transition: 0.3s;
            -ms-transition: 0.3s;
             -o-transition: 0.3s;
                transition: 0.3s;
    }

    .get_flat_img:hover:before {
        opacity: 0.8;
        -webkit-transform: scale(1.1);
           -moz-transform: scale(1.1);
            -ms-transform: scale(1.1);
             -o-transform: scale(1.1);
                transform: scale(1.1);
    }

    .get_flat_img img {
        object-fit: cover;
        display: block;
        width: 100%;
        height: 100%;
    }


.get_flat .tm {
    text-align: left;
    font-size: 40px;
    line-height: 44px;
}

    .get_flat .tm:before {
        left: 0;
        margin-left: 0;
    }

.get_flat .flat_nav {
    margin-top: 34px;
}

.get_flat .flat_nav .btn_main {
    background: #FFF;
    color: #404040;
    border: 2px solid #848794;
}

    .get_flat .flat_nav .btn_main:hover {
        background: #848794;
        color: #fff;
    }

    .get_flat .flat_nav .btn_main.act {
        pointer-events: none;
    }

.get_flat .flat_nav {
    margin-left: -17px;
}

.get_flat .flat_nav > li {
    padding-left: 17px;
}

.get_flat .flat_area {
    margin-top: 34px;
}

    .get_flat .flat_area__title {
        color: #404040;
    }

    .get_flat .fas__num {
        color: #404040;
    }

    .get_flat .fas__num:before {
        background: #404040;
    }

.form_style3 {
    display: flex;
    margin-top: 70px;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
}

    .form_style3 .in_style {
        width: calc(50% - 16px);
    }

    .form_style3 .btn_main {
        padding-left: 10px;
        padding-right: 10px;
        min-width: 20px;
        width: calc(50% - 16px);
    }

    .form_style3 .in_style + .in_style + .in_style, .form_style3 .btn_main {
        margin-top: 27px;
    }


.bg1 {
    background: #464451;
}

.oplata {
    padding-top: 44px;
    padding-bottom: 80px;
}

.oplata .tm {
    color: #FFF;
}

.oplata__list {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
    margin-top: 80px;
    margin-left: -32px;
}

    .oplata__list > li {
        width: 16.6666666666%;
        padding-left: 32px;
        -webkit-box-sizing: border-box;
           -moz-box-sizing: border-box;
                box-sizing: border-box;
    }

.ol__item {
    position: relative;
    top: 0;
    cursor: pointer;
    background: rgba(255, 255, 255, 0.03);
    border: 1px solid rgba(255, 255, 255, 0.5);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.3);
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 5px;
    height: 120px;
    display: flex;
    padding-top: 42px;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-transition: 0.3s;
       -moz-transition: 0.3s;
        -ms-transition: 0.3s;
         -o-transition: 0.3s;
            transition: 0.3s;
}

    .ol__item:hover {
        top: -10px;
    }

.ol__ico {
    position: absolute;
    width: 80px;
    height: 80px;
    background: rgba(255, 255, 255, 0.03);
    border: 1px solid rgba(255, 255, 255, 0.5);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(5px);
    border-radius: 50px;
    left: 50%;
    margin-left: -40px;
    top: -40px;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
}

    .ol__ico img {
        display: block;
    }

.ol__text {
    text-align: center;
    color: #FFF;
    font-size: 24px;
    line-height: 120%;
}

.hod {
    padding-bottom: 80px;
    overflow: hidden;
}

.hod .tm {
    color: #FFF;
}

.hod__nav {
    display: flex;
    margin-top: 30px;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.hod_year {
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

    .hod_year div {
        cursor: pointer;
        font-size: 40px;
        line-height: 100%;
        color: #FFF;
        font-weight: 600;
        position: relative;
    }

    .hod_year div + div {
        margin-left: 27px;
    }

    .hod_year div.act:before {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 4px;
        background: #95813A;
        left: 0;
        bottom: -10px;
    }

.hod_mounth {
    display: flex;
    padding-top: 6px;
    padding-left: 30px;
    margin-bottom: -15px;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-align-items: center;
            align-items: center;
}

    .hod_mounth > div {
        cursor: pointer;
        margin-left: 16px;
        font-size: 24px;
        line-height: 120%;
        color: #FFF;
        margin-bottom: 15px;
        -webkit-transition: 0.3s;
           -moz-transition: 0.3s;
            -ms-transition: 0.3s;
             -o-transition: 0.3s;
                transition: 0.3s;
    }

    .hod_mounth > div.act, .hod_mounth > div:hover {
        color: #95813A;
    }

.hod__slider {
    overflow: inherit !important;
    margin-top: 30px;
}

.hod__slider .swiper-slide {
    width: 1104px;
}

.hod__slide img {
    display: block;
    width: 100%;
}

.hod__slider .swiper-pagination {
    display: none;
}

.about {
    background: #D9D9D9;
}

.about .tm {
    text-align: left;
    font-size: 48px;
    line-height: 100%;
    color: #404040;
    font-weight: 600;
}

    .about .tm:before {
        left: 0;
        margin-left: 0;
    }

.about .wmain {
    display: flex;
}

.about_l {
    width: 650px;
    min-width: 650px;
    padding-top: 36px;
    padding-bottom: 36px;
    padding-right: 40px;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
}

.about_title {
    font-size: 30px;
    line-height: 120%;
    color: #000;
    margin-top: 32px;
}

.about_text {
    margin-top: 32px;
    font-size: 16px;
    line-height: 120%;
}

.about_text a {
    color: #000;
}

.about_img {
    position: relative;
    display: flex;
    height: 100%;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    width: calc(100vw / 2 + (100% - 870px));

}

    .about_logo {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 5;
        display: flex;
        padding-left: 60px;
        padding-right: 60px;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
        -webkit-box-sizing: border-box;
           -moz-box-sizing: border-box;
                box-sizing: border-box;
    }

    .about_logo img {
        display: block;
        max-width: 100%;
    }

.about_img > img {
    display: block;
    width: 100%;
    object-fit: cover;
    display: block;
    width: 100%;
    height: 100%;
}

.contact {
    padding-top: 60px;
}

.contact .wmain2 {
    display: flex;
}

.contact .tm {
    text-align: left;
    color: #FFF;
    font-size: 48px;
    line-height: 110%;
    color: #FFF;
    font-weight: 600;
}

    .contact .tm:before {
        left: 0;
        margin-left: 0;
    }

.contact_l {
    display: flex;
    margin-left: -180px;
    width: 100%;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}
    .contact_l > img {
        display: block;
        object-fit: cover;
        width: 100%;
        height: 100%;
    }


.contact_r {
    width: 600px;
    min-width: 600px;
    margin-left: 55px;
    padding-bottom: 28px;
}

.contact_list {
    display: block;
    margin: 0;
    padding: 0;
    list-style: none;
    margin-top: 32px;
}

    .contact_list > li {
        font-size: 24px;
        line-height: 120%;
        color: #FFF;
        display: flex;
    }

        .contact_list > li a {
            color: #FFF;
            text-decoration: none;
        }

    .contact_list > li + li {
        margin-top: 32px;
    }

    .contact_list > li i {
        display: block;
        width: 35px;
        position: relative;
        top: 5px;
    }

    .contact_list > li i img {
        display: block;
        max-width: 100%;
    }

.contact_form {
    display: block;
    margin-top: 26px;
}

.contact_form_title {
    font-size: 24px;
    line-height: 110%;
    font-weight: 600;
    color: #FFF;
    display: inline-block;
}
.contact_form_title .btn_main {
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 15px;
    display: inline-block;
}

.contact_form_inner {
    max-width: 394px;
    margin-top: 24px;
}

.contact_form_inner .in_style {
    margin-bottom: 24px;
    width: 100%;
}

textarea.in_style {
    padding-top: 15px;
    height: 80px;
}

.contact_form_inner .btn_main {
    width: 100%;
}

.footer {
    background: #090A0E;
}

    .footer .wmain {
        display: flex;
        padding-top: 35px;
        padding-bottom: 37px;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: space-between;
                justify-content: space-between;
    }

.footer_l {
    white-space: nowrap;
}

.f_logo {
    display: block;
    max-width: 100%;
}

.f_copy {
    margin-top: 21px;
    font-size: 14px;
    line-height: 16px;
    color: #FFF;
}

.footer_c {
    max-width: 840px;
    margin: 0 auto;
    font-size: 14px;
    line-height: 16px;
    color: #FFF;
    padding-left: 32px;
    padding-right: 32px;
    text-align: center;
}

    .footer_c a {
        color: #FFF;
    }

.f_create {
    display: flex;
    text-align: right;
    color: #FFF;
    text-decoration: none;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
}

    .f_create img {
        display: block;
        margin-top: 16px;
    }

    .f_create:hover {
        text-decoration: underline;
    }

.fixed_menu .hl_city {
    display: none;
}


.popup_rgba {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 90000;
    overflow: auto;
    background: rgba(0,0,0,0.4);
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
}

.pu_table {
    display: table;
    width: 100%;
    height: 100%;
}

.pu_cell {
    display: table-cell;
    width: 100%;
    vertical-align: middle;
    padding-top: 30px;
    padding-bottom: 30px;
}

.pu_inner {
    position: relative;
    background: rgba(0, 0, 0, 0.79);
    border: 1px solid #FFFFFF;
    box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(5px);
    max-width: 541px;
    margin: 0 auto;
    padding: 58px 20px 37px 20px;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
}

.pu_inner .tm {
    color: #fff;
}

.closeform {
    cursor: pointer;
    position: absolute;
    right: 16px;
    top: 17px;
}

    .closeform svg {
        display: block;
    }

    .closeform:hover rect {
        fill: #fff;
    }

.pu_title {
    text-align: center;
    font-size: 32px;
    line-height: 120%;
    color: #FFF;
    font-weight: 600;
    position: relative;
    padding-bottom: 24px;
}

.pu_title:before {
    content: "";
    display: block;
    position: absolute;
    width: 84px;
    height: 4px;
    left: 50%;
    margin-left: -42px;
    bottom: 0;
    background: var(--c1);
}

.pu_form {
    display: block;
    max-width: 394px;
    margin: 0 auto;
    margin-top: 32px;
}

    .pu_form .in_style, .pu_form .select_style {
        width: 100%;
        margin-bottom: 20px;
    }

    .pu_form .btn_main {
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
    }

.pu_text_bottom {
    text-align: center;
    color: #FFF;
    font-size: 12px;
    line-height: 15px;
    text-transform: uppercase;
    max-width: 396px;
    margin: 0 auto;
    margin-top: 31px;
}

.select_style {
    display: block;
    width: 100%;
    background: #fff url("../img/select_str.svg") no-repeat right center;
    border: none;
    font-size: 16px;
    color: #000;
    cursor: pointer;
    height: 54px;
    padding-left: 19px;
    padding-right: 50px;
    box-shadow: inset 2px 2px 3px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
}

.contact_r .contact_l {
    display: none;
}

.pu_flat {
    display: flex;
    margin-top: 20px;
    align-items: flex-start;
}

.pu_flat_main {
    max-width: 820px;
}

.pu_flat__l {
    width: 320px;
    min-width: 320px;
}

.pu_flat__r {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-left: 32px;
    box-sizing: border-box;
}

    .pu_flat__r img {
        max-width: 100%;
    }

.pu_flat__info {
    color: #fff;
    font-size: 18px;
}

.pu_flat_form_l {
    margin-top: 20px;
}

.pu_flat_form_l input {
    width: 100%;
}

.pu_flat_form_l .btn_main {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 15px;
}

.pu_flat_form_l input + input {
    margin-top: 15px;
}

.pu_flat_main .tm {
    color: #fff;
}


@media screen and (max-width: 1960px) {
    .wmain {
        width: 100%;
    }

    .about_img {
        width: calc(100% + 90px);
    }

    .get_flat_img {
        width: calc(100% + 90px);
    }
}

@media screen and (max-width: 1600px) {
    .contact_list > li {
        font-size: 18px;
    }
    .contact_list > li i {
        top: 0;
    }
    .contact_list > li + li {
        margin-top: 16px;
    }
    .oplata__list {
        margin-bottom: -70px;
        -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
                flex-wrap: wrap;
    }
    .oplata__list > li {
        width: 33.333333333%;
        margin-bottom: 70px;
    }
    body .swiper-button-prev:before, body .swiper-button-next:before {
        background-position: center center;
        background-repeat: no-repeat;
        -webkit-background-size: 50px;
             -o-background-size: 50px;
                background-size: 50px;
    }
}

@media screen and (max-width: 1460px) {
    .contact_r {
        width: 420px;
        min-width: 420px;
    }
    .children_play .children_img:before {
        -webkit-background-size: 120px;
             -o-background-size: 120px;
                background-size: 120px;
    }
    .children_name {
        font-size: 20px;
        line-height: 120%;
        margin-top: 20px;
    }
    .city_infra__list2 .cil__name {
        font-size: 18px;
        line-height: 120%;
    }
    .city_infra__list2 .cil__name_inner {
        font-size: 18px;
        line-height: 120%;
    }
    .hl_phone {
        font-size: 22px;
    }
    .hl__name {
        font-size: 18px;
        line-height: 120%;
    }
    .cil__name {
        font-size: 18px;
        line-height: 120%;
        padding-top: 12px;
        padding-bottom: 16px;
    }
    .cil__name_inner {
        font-size: 18px;
        line-height: 120%;
    }
    .flat_list > li {
        width: 33.3333333%;
    }

    .get_flat_l {
        width: 100%;
        min-width: 600px;
    }
}

@media screen and (max-width: 1380px) {
    .hod_year div {
        font-size: 28px;
    }

    .hod__slider .swiper-slide {
        width: 100%;
    }
    .get_flat_r {
        width: 100%;
        min-width: 20px;
    }
    .get_flat_img {
        width: calc(100% + 30px);
    }

        .get_flat_img:before {
            -webkit-background-size: 130px;
                 -o-background-size: 130px;
                    background-size: 130px;
        }
}

@media screen and (max-width: 1200px) {
    .about_img {
        width: calc(100% + 30px);
    }
    .plr {
        padding-right: 30px;
    }
    .fi__name {
        font-size: 20px;
    }
    .fi_img {
        height: 300px;
    }

        .fi_img img {
            max-height: 270px;
        }
    .logo img {
        max-width: 320px;
    }
    .about .tm {
        font-size: 34px;
    }
    .about_title {
        font-size: 24px;
    }
    .oplata__list > li {
        width: 33.3333333%;
    }
    .hl__name {
        font-size: 16px;
    }
    .about_l {
        width: 460px;
        min-width: 460px;
    }
    .cil__img {
        height: 300px;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
    }

        .cil__img img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
}

@media screen and (max-width: 1100px) {
    .contact .tm {
        font-size: 30px;
    }
    .contact_list > li {
        font-size: 18px;
    }
    .contact_list > li i {
        top: 1px;
    }
    .contact_list > li + li {
        margin-top: 14px;
    }
    .contact_form_title {
        font-size: 18px;
    }
    .contact_r {
        width: 320px;
        min-width: 320px;
        margin-left: 30px;
    }
    .flat_nav {
        margin-bottom: -15px;
        -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
                flex-wrap: wrap;
    }
    .flat_nav > li {
        padding-left: 15px;
        width: auto;
        margin-bottom: 15px;
    }
    .flat_nav .btn_main {
        padding-left: 15px;
        padding-right: 15px;
    }
    .form_style1 .in_style, .form_style2 .in_style {
        width: 100% !important;
        margin-bottom: 15px;
    }

    .form_style1, .form_style2 {
        display: block;
        max-width: 420px;
        margin: 0 auto;
        margin-top: 25px;
    }

    .form_style1 .btn_main, .form_style2 .btn_main {
        width: 100%;
    }
}

@media screen and (max-width: 960px) {
    .city_infra__list2 > li + li + li + li {
        width: 50%;
    }
    .footer .wmain {
        -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
                flex-wrap: wrap;
    }

    .footer_c {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 30px;
        -webkit-order: 1;
            -ms-order: 1;
                order: 1;
    }
    .footer_l {
        -webkit-order: 2;
            -ms-order: 2;
                order: 2;
    }
    .footer_r {
        -webkit-order: 3;
            -ms-order: 3;
                order: 3;
    }
    .flat_list > li {
        padding-left: 20px;
        width: 50%;
    }
    .flat_list {
        margin-left: -20px;
    }
    .header__list {
        margin-left: -20px;
        margin-right: -20px;
        width: calc(100% + 165px);
        margin-top: 0px;
        margin-bottom: 0;
        overflow: auto;
        padding-top: 40px;
        padding-left: 125px;
        padding-right: 40px;
        margin-left: -125px;
        margin-right: -40px;
        -ms-overflow-style: none;
        scrollbar-width: none;
        overflow: -moz-scrollbars-none;
        -ms-overflow-style: none;
    }
    .header__list::-webkit-scrollbar {
        width: 0;
        height: 0;
    }
    .header__list > li {
        margin-bottom: 0;
        width: auto;
        min-width: 140px;
    }
        .header__list > li + li {
            margin-left: 20px;
        }
    .hl__name {
        font-size: 15px;
    }

    .header .wmain {
        height: auto;
        padding-top: 150px;
        min-height: 20px;
        padding-bottom: 260px;
    }
}

@media screen and (max-width: 940px) {
    .hod_mounth > div {
        font-size: 18px;
    }
    .hod_year div {
        font-size: 22px;
    }
    .hod_year div + div {
        margin-left: 14px;
    }
    .hod_mounth {
        padding-top: 3px;
        padding-left: 20px;
    }
    .get_flat_l {
        padding-right: 0;
    }
    .get_flat .wmain {
        display: block;
    }
    .get_flat_img {
        width: 100%;
    }
    .get_flat_r {
        width: 100%;
        height: 300px;
    }
    .get_flat_l {
        min-width: 20px;
    }
    .logo img {
        max-width: 200px !important;
    }
    .hl_phone {
        font-size: 18px;
    }
    .hl_city {
        font-size: 14px;
        line-height: 16px;
    }
    .header_center h1 {
        font-size: 6vw;
    }
    .oplata__list > li {
        width: 50%;
    }

    .plr {
        padding-left: 125px;
        padding-right: 40px;
    }

    .hl_top {
        padding-left: 15px;
    }
}

@media screen and (max-width: 880px) {
    .about_logo {
        padding-left: 25px;
        padding-right: 25px;
    }
    .about .tm {
        font-size: 26px;
    }
    .about_title {
        font-size: 20px;
    }
    .about_l {
        width: 360px;
        min-width: 360px;
    }
}

@media screen and (max-width: 840px) {
    .contact_l {
        height: 400px;
        display: none;
    }
    .contact_mobile_map {
        display: flex !important;
        margin-top: 30px;
    }
    .contact .wmain2 {
        display: block;
    }
    .contact_l {
        width: 100%;
        margin-left: 0;
    }
    .contact_r {
        width: 100%;
        min-width: 20px;
        margin-left: 0;
        margin-top: 0;
    }
    .city_infra__list > li {
        width: 50%;
    }

    .city_infra__list > li:last-child {
        width: 100%;
    }

    .children_play .children_img:before {
        -webkit-background-size: 80px;
             -o-background-size: 80px;
                background-size: 80px;
    }
}

@media screen and (max-width: 720px) {
    .about_l {
        width: 100%;
        min-width: 20px;
        -webkit-order: 2;
            -ms-order: 2;
                order: 2;
    }
    .tm {
        font-size: 32px;
    }

    .about_r {
        width: 100%;
        height: 120px;
        -webkit-order: 1;
            -ms-order: 1;
                order: 1;
    }
    .about_img {
        margin-left: -20px;
        width: calc(100% + 40px);
    }
    .about_logo img {
        max-width: 200px;
    }
    .about_l {
        padding-right: 0;
    }
    .about .wmain {
        -webkit-flex-direction: column;
            -ms-flex-direction: column;
                flex-direction: column;
    }
    .header_center h1 {
        font-size: 7vw;
    }
    .fixed_menu_nav {
        top: 0;
    }
    .hl_top .hl_city {
        font-size: 0;
        line-height: 0;
        opacity: 0;
    }
    .fixed_l_logo {
        display: none;
    }

    .fixed_menu_ico {
        top: 23px;
        left: 20px;
        margin-left: 0;
        margin-top: 0;
    }

    .hl_top {
        left: 70px;
    }

    .hl_top {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }

    .hl_top .wmain {
        height: 70px;
    }

    .fixed_l {
        height: 70px;
        width: 70px;
    }

    .fixed_l__act {
        height: 100vh;
        width: 360px;
    }

    .fixed_menu {
        -webkit-transform-origin: left 150px;
           -moz-transform-origin: left 150px;
            -ms-transform-origin: left 150px;
             -o-transform-origin: left 150px;
                transform-origin: left 150px;
    }

    .fixed_menu .hl_city {
        display: flex;
        font-size: 18px;
        line-height: 120%;
        margin-top: 15px;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
    }

    .fixed_l__act {
        overflow: hidden;
        overflow-y: auto;
        padding-top: 60px;
        padding-bottom: 60px;
    }

    .plr {
        padding-left: 20px;
        padding-right: 20px;
    }


}

@media screen and (max-width: 640px) {
    .fixed_menu_nav a + a {
        margin-top: 12px;
    }
    .fixed_menu_logo img {
        max-width: 210px;
    }
    .fixed_menu_nav {
        margin-top: 9px;
        padding-top: 18px;
    }
}

@media screen and (max-width: 620px) {
    .pu_flat__l {
        width: 250px;
        min-width: 250px;
    }
}

@media screen and (max-width: 580px) {
    .ol__text {
        font-size: 18px;
    }
    .hl_phone {
        display: none;
    }

    .hl_right .btn_main {
        margin-top: 0;
        white-space: nowrap;
    }

    .header .wmain {
        padding-top: 120px;
        padding-bottom: 60px;
    }

    .form_style3 {
        display: block;
        max-width: 420px;
        margin: 0 auto;
        margin-top: 32px;
    }

    .form_style3 .in_style {
        width: 100%;
        margin-bottom: 15px;
    }

    .form_style3 .btn_main {
        width: 100%;
    }
    .form_style3 .in_style + .in_style + .in_style, .form_style3 .btn_main {
        margin-top: 0;
    }

    .header .wmain {
        padding-top: 90px;
        padding-bottom: 250px;
    }
    .hl__name {
        font-size: 12px;
    }

    .hl__ico {
        width: 60px;
        height: 60px;
        margin-left: -30px;
        top: -30px;
    }

        .hl__ico img {
            display: block;
            max-width: 30px;
            max-height: 30px;
        }

    .hl__item {
        height: 100px;
        padding-top: 25px;
        padding-left: 10px;
        padding-right: 10px;
    }
    .header .wmain {
        padding-bottom: 200px;
    }

    .header__list {
        bottom: 32px;
    }
}

@media screen and (max-width: 560px) {
    .pu_title br {
        display: none;
    }
    .flat_nav {
        margin-left: -16px;
    }
    .flat_nav .btn_main {
        font-size: 15px;
        line-height: 120%;
        padding-top: 8px;
        padding-bottom: 11px;
    }
    .flat_item .btn_main {
        font-size: 16px;
        padding-top: 8px;
        padding-bottom: 11px;
    }
    .city_infra__list > li {
        padding-left: 20px;
    }
    .city_infra__list {
        margin-left: -20px;
    }
    .cil__name {
        font-size: 16px;
    }
    .cil__name_inner {
        font-size: 15px;
    }
    .cil__text {
        font-size: 15px;
        line-height: 120%;
    }
    .cil__content {
        padding-left: 5px;
        padding-right: 5px;
    }
    .header_center .btn_m {
        padding-left: 30px;
        padding-right: 30px;
        font-size: 18px;
    }
    .head_sub {
        font-size: 20px;
    }
    .children_list {
        -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
                flex-wrap: wrap;
    }

    .children_list > li {
        width: 100%;
    }

    .children_map {
        height: 500px;
    }

    .gallery_th .swiper-button-prev, .gallery_th .swiper-button-next {
        display: none;
    }

    .gallery_big .swiper-button-prev, .gallery_big .swiper-button-next {
        display: block;
    }

    .gallery_big {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .gallery_th {
        display: none !important;
    }

    .logo {
        margin-left: 0;
        margin-right: 0;
    }

    .logo img {
        max-width: 150px !important;
    }

    .hl_top .hl_city {
        display: none;
    }
}

@media screen and (max-width: 480px) {
    .pu_flat {
        flex-wrap: wrap;
    }

    .pu_flat__l {
        width: 100%;
        order: 2;
        min-width: 20px;
        margin-top: 20px;
    }

    .pu_flat__r {
        order: 1;
        padding-left: 0;
    }
    .hod__nav {
        display: block;
    }
    .flat_list > li {
        width: 100%;
    }
    .hod_mounth {
        padding-left: 0;
        padding-top: 0;
        margin-top: 25px;
        margin-left: -10px;
        margin-bottom: -10px;
    }
    .hod_mounth > div {
        margin-left: 10px;
        font-size: 16px;
        margin-bottom: 10px;
    }
    .oplata {
        padding-bottom: 40px;
    }
}

@media screen and (max-width: 420px) {
    .get_flat_r {
        height: 180px;
    }
    .get_flat_img:before {
        -webkit-background-size: 90px;
             -o-background-size: 90px;
                background-size: 90px;
    }
    .pu_form .btn_main {
        font-size: 18px;
    }
    .pu_title {
        font-size: 24px;
    }
    .pu_inner {
        padding-top: 48px;
    }
    .fixed_menu_nav a {
        font-size: 18px;
        line-height: 20px;
    }
    .fixed_menu_nav a + a {
        margin-top: 5px;
    }
    .fixed_menu_logo img {
        max-width: 170px;
    }
    .fixed_menu .hl_city {
        font-size: 16px;
    }
    .f_copy {
        font-size: 12px;
    }
    .f_create {
        font-size: 12px;
    }
    .f_create img {
        max-width: 98px;
    }
    .f_logo {
        max-width: 150px;
    }
    .contact_l {
        height: 400px;
        max-height: 80vh;
    }
    .oplata__list > li {
        padding-left: 15px;
    }
    .oplata__list {
        margin-left: -15px;
    }
    .ol__text {
        font-size: 16px;
    }
    .get_flat .tm {
        font-size: 24px;
        line-height: 120%;
    }
    .flat_list {
        margin-top: 56px;
    }
    .flat_area__title {
        font-size: 18px;
    }
    .city_infra__list > li {
        width: 100%;
    }
    .city_infra__list > li + li + li + li {
        width: 100%;
    }
    .cil__img {
        height: 240px;
    }
    .children_name {
        font-size: 16px;
    }
    .form_style2 {
        margin-top: 0px;
    }
    .city_infra__list2 .cil__name {
        font-size: 15px;
        padding-bottom: 13px;
    }
    .tm {
        font-size: 24px;
        padding-bottom: 28px;
    }
    .tm_dop {
        font-size: 18px;
        line-height: 120%;
        margin-top: 20px;
    }
    .hl__name {
        font-size: 14px;
    }
    .header__list {
        margin-left: -10px;
    }
    .header__list > li {
        width: calc(50% - 5px);
    }
    .hl_right .btn_main {
        font-size: 10px;
        border-width: 1px;
    }

    .logo img {
        max-width: 100px;
    }

    .hl_top {
        padding-left: 10px;
        padding-right: 10px;
    }

    .header__list {
        padding-left: 20px;
        padding-right: 20px;
        margin-left: -20px;
        margin-right: -20px;
        width: calc(100% + 40px);
    }

    .header__list > li + li {
        margin-left: 10px;
    }
}

@media screen and (max-width: 360px) {
    .hl__item {
        padding-left: 5px;
        padding-right: 5px;
    }
    .head_sub {
        font-size: 18px;
    }
    .header_center .btn_m {
        font-size: 16px;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 8px;
        padding-bottom: 12px;
    }
    .fixed_menu {
        padding-left: 20px;
    }

    .fixed_l__act {
        width: 100%;
    }

    .logo img {
        max-width: 110px !important;
    }

    .hl_right .btn_main {
        font-size: 8px;
        padding-top: 1px;
        padding-bottom: 1px;
    }
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: var(--bs-modal-zindex);
    display: none;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
}
